import React, { useState, useEffect } from 'react';
import './checkoutBanner.css';
import cart from "../Images/cart.png"
import incomplete from "../Images/incomplete.png";
import complete from "../Images/complete.png";
function CheckoutBanner({ campaigns, createAdCampagin  }) {
    const [totalBudget, setTotalBudget] = useState(500);
    const [checkoutAllowed, setCheckoutAllowed] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    useEffect(() => {
        let total = 0
        campaigns.forEach((campaign) => {
            total += parseInt(campaign.budget)
            setTotalBudget(total)
        })
        if (document.querySelectorAll(".missingInputs").length === 0) {
            if (totalBudget >= 500 && termsAccepted) {
                setCheckoutAllowed(true)

            } else {
                setCheckoutAllowed(false)
            }
        } else {
            setCheckoutAllowed(false)
        }


    }, [campaigns, termsAccepted])




    return (
        <div className="checkout-banner">
            <div id="cartIcon">
                <img src={cart} style={{height:'16px'}} alt="cart"></img>
                <p style={{color:'white', marginLeft:'12px'}}>Shopping Cart</p>
            </div>


            <div style={{ padding: '20px' }}>
                {campaigns.map((campaign, key) => (
                    <div style={{ marginBottom: '16px' }} key={key}>
                        <div className="line-item" key={campaign.id}>
                            <h4>Campaign #{key + 1}</h4>


                            <p>${campaign.budget}</p>
                        </div>
                        {campaign.details && campaign.details.campaign_name ?
                            <p style={{ marginTop: '-10px' }}>{"Campaign Name: " + campaign.details.campaign_name}</p> : null
                        }

                        {
                            campaign.creativeReady ? (
                                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '0px' }}>
                                    <img src={complete} style={{ width: '16px' }} alt="complete" />
                                    <p className="completedSection">Creative Section Complete</p>
                                </div>
                            ) : (
                                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '0px' }}>
                                    <img src={incomplete} style={{ width: '16px' }} alt="incomplete" />
                                    <p className="missingInputs">Creative Section Incomplete</p>
                                </div>
                            )
                        }


                        {campaign.targeting && campaign.targeting.isComplete ?
                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '0px' }}>
                                <img src={complete} style={{ width: '16px' }} alt="complete"></img>
                                <p className="completedSection">Targeting Section Complete</p>
                            </div> : <div style={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '0px' }}>
                                <img src={incomplete} alt="incomplete" style={{ width: '16px' }} ></img>
                                <p className="missingInputs">Targeting Section Incomplete</p>
                            </div>
                        }
                        {campaign.details && campaign.details.isComplete ?
                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '0px' }}>
                                <img src={complete} style={{ width: '16px' }} alt="complete"></img>
                                <p className="completedSection">Campaign Section Complete</p>
                            </div> : <div style={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '0px' }}>
                                <img style={{ width: '16px' }} src={incomplete} alt="incomplete"></img>
                                <p className="missingInputs">Campaign Section Incomplete</p>
                            </div>
                        }
                        {campaign.budget && campaign.budget >= 500 ?
                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '0px' }}>
                                <img src={complete} style={{ width: '16px' }} alt="complete"></img>
                                <p className="completedSection">Budget Section Complete</p>
                            </div> : <div style={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '0px' }}>
                                <img style={{ width: '16px' }} src={incomplete} alt="incomplete"></img>
                                <p className="missingInputs">Insufficient Budget</p>
                            </div>
                        }

                    </div>
                ))}

                <div style={{ textAlign: 'right', marginBottom: '0px' }}>
                    <h2 style={{ marginBottom: '0px' }}>Total: ${totalBudget}</h2>
                </div>
                <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center', marginTop: '0px', width: 'fit-content', padding: '10px 0' }}>
                    <input
                        type="checkbox"
                        style={{ margin: 0 }}
                        checked={termsAccepted}
                        onChange={() => setTermsAccepted(!termsAccepted)}
                    />
                    <p style={{ marginLeft: '8px', fontWeight: 500, fontSize: '14px', opacity: 0.9, display: 'inline', whiteSpace: 'nowrap' }}>
                        I agree to the <a href="https://sites.google.com/streamwave.io/termsofservice/home">terms of service</a>
                    </p>
                </div>




                <div className="checkout-button-container">
                    <button
                        className="checkout-button"
                        disabled={!checkoutAllowed}
                        onClick={() => {
                            if (totalBudget >= 500) {
                                console.log('Proceed to checkout');
                                createAdCampagin(totalBudget)
                            }
                        }}
                    >
                        Checkout
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CheckoutBanner;
