import React, { useState, useRef } from 'react';
import { storage } from '../firebase/firebaseApp';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import LottieAnimation from '../Lottie/Lottie';
import confetti from "../Lottie/98883-confetti.json";
import OTTRetargetingUpload from './OTTRetargetingUpload';
import errorIcon from '../Images/error.svg';
import './uploadBox.css';

const UploadCreative = (props) => {
    const [logo, setLogo] = useState(null);
    const [logoError, setLogoError] = useState(null);
    const [companionBanner, setCompanionBanner] = useState(null);
    const [companionBannerError, setCompanionBannerError] = useState(null);
    const [error, setError] = useState(null);
    const [files, setFiles] = useState([]); // Track all files (success or failed)
    const [requireLogo, setRequireLogo] = useState(false);
    const [requireCompanionBanner, setRequireCompanionBanner] = useState(false);
    const [showVideoRequirements, setShowVideoRequirements] = useState(true); // Video requirements toggle
    const [showAudioRequirements, setShowAudioRequirements] = useState(true); // Audio requirements toggle
    const [showUploadButton, setShowUploadButton] = useState(false); // Controls visibility of the upload button
    const [initialUploadDone, setInitialUploadDone] = useState(false); // Tracks if the first valid file has been uploaded
    const videoTypes = ['video/mp4'];
    const audioTypes = ['audio/mp3', 'audio/wav', 'audio/mpeg'];

    const fileInputRef = useRef(null);
    const additionalFileInputRef = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length > 0) {
            handleFiles(selectedFiles);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        handleFiles(droppedFiles);
    };

    const handleFiles = (selectedFiles) => {
        let hasVideo = false;
        let hasAudio = false;

        selectedFiles.forEach(file => {
            if (videoTypes.includes(file.type)) {
                hasVideo = true;
                validateAndUploadVideo(file);
            } else if (audioTypes.includes(file.type)) {
                hasAudio = true;
                validateAndUploadAudio(file);
            } else {
                setFiles(prev => [...prev, { file, error: 'Invalid file type. Accepted types are MP4, MP3, WAV.', progress: null }]);
            }
        });

        if (hasVideo || hasAudio) {
            setShowUploadButton(true);
        }
        if (hasVideo) setRequireLogo(true);
        if (hasAudio) setRequireCompanionBanner(true);

        if (!initialUploadDone && (hasVideo || hasAudio)) {
            setInitialUploadDone(true);
        }
    };

    const validateAndUploadVideo = (file) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            const video = document.createElement('video');
            video.src = event.target.result;

            video.addEventListener('loadedmetadata', function () {
                const height = video.videoHeight;
                const width = video.videoWidth;
                const duration = video.duration;

                const fileSizeMB = file.size / 1000000;
                const bitrate = (file.size * 8) / (duration * 1000000);

                if (height < 1080 || width < 1920) {
                    setFiles(prev => [...prev, { file, error: 'Resolution too low: Minimum acceptable resolution is 1920x1080.', progress: null }]);
                    return;
                }
                if (fileSizeMB > 499) {
                    setFiles(prev => [...prev, { file, error: 'File too large: Maximum file size is 500 MB.', progress: null }]);
                    return;
                }
                if (bitrate < 1.2) {
                    setFiles(prev => [...prev, { file, error: 'Bitrate too low: Video bitrate must be over 1.2 mbps.', progress: null }]);
                    return;
                }

                uploadFile(file);
            });
        };

        reader.readAsDataURL(file);
    };

    const validateAndUploadAudio = (file) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            const audio = new Audio();
            audio.src = event.target.result;

            audio.addEventListener('loadedmetadata', function () {
                const duration = audio.duration;
                const fileSizeMB = file.size / 1000000;
                const bitrate = (file.size * 8) / (duration * 1000);

                const isValidDuration = duration >= 12 && duration <= 33;

                if (!isValidDuration) {
                    setFiles(prev => [...prev, { file, error: 'Invalid Duration: Audio must be between 12 and 33 seconds.', progress: null }]);
                    return;
                }
                if (bitrate < 160) {
                    setFiles(prev => [...prev, { file, error: 'Bitrate too low: Audio bitrate must be at least 160 kbps.', progress: null }]);
                    return;
                }
                if (fileSizeMB > 50) {
                    setFiles(prev => [...prev, { file, error: 'File too large: Maximum file size is 50 MB.', progress: null }]);
                    return;
                }

                uploadFile(file);
            });
        };

        reader.readAsDataURL(file);
    };

    const uploadFile = (file) => {
        setFiles(prev => [...prev, { file, error: null, progress: 0 }]);
        const now = new Date();
        const year = now.getFullYear();
        const month = now.toLocaleString('default', { month: 'long' });
        const filePath = `campaigns/${year}/${month}/${file.name}`;
        const storageRef = ref(storage, filePath);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setFiles(prevFiles => prevFiles.map(f => f.file === file ? { ...f, progress } : f));
            },
            (error) => {
                setFiles(prevFiles => prevFiles.map(f => f.file === file ? { ...f, error: error.message, progress: null } : f));
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    props.updateCreative(file.type, downloadURL, props.camp_id);
                    setFiles(prevFiles => prevFiles.map(f => f.file === file ? { ...f, progress: null, downloadURL } : f));
                });
            }
        );
    };

    const removeFile = (file, fileObj) => {
        props.removeCreative(fileObj.downloadURL);
        setFiles(prevFiles => {
            const updatedFiles = prevFiles.filter(f => f.file !== file);
            if (updatedFiles.length === 0) {
                setInitialUploadDone(false);
            }
            updateUploadRequirements(updatedFiles);
            return updatedFiles;
        });
    };

    const updateUploadRequirements = (updatedFiles) => {
        const hasVideo = updatedFiles.some(fileObj => fileObj.file.type.startsWith('video/') && fileObj.downloadURL);
        const hasAudio = updatedFiles.some(fileObj => fileObj.file.type.startsWith('audio/') && fileObj.downloadURL);

        setRequireLogo(hasVideo);
        setRequireCompanionBanner(hasAudio);

        setShowUploadButton(updatedFiles.length > 0);
    };

    const handleLogoUpload = (event) => {
        if (event.target.files[0] !== undefined) {
            const selectedFile = event.target.files[0];
            const fileSize = selectedFile.size / 1024 / 1024;

            if (fileSize > 2) {
                setLogoError('Please upload an image that is under 2MB');
                setLogo(null);
            } else {
                uploadLogo(selectedFile);
                setLogoError(null);
            }
        }
    };

    const handleCompanionBannerUpload = (event) => {
        if (event.target.files[0] !== undefined) {
            const selectedFile = event.target.files[0];
            const fileSize = selectedFile.size / 1024 / 1024;
            const img = new Image();

            img.src = URL.createObjectURL(selectedFile);
            img.onload = () => {
                if (img.width !== 300 || img.height !== 250) {
                    setCompanionBannerError('Invalid resolution: Companion Banner must be 300×250.');
                    setCompanionBanner(null);
                    return;
                }

                if (fileSize > 2) {
                    setCompanionBannerError('Please upload an image that is under 2MB');
                    setCompanionBanner(null);
                } else {
                    uploadCompanionBanner(selectedFile);
                    setCompanionBannerError(null);
                }
            };
        }
    };

    function uploadLogo(file) {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.toLocaleString('default', { month: 'long' });
        const filePath = `campaigns/${year}/${month}/${file.name}`;
        const storageRef = ref(storage, filePath);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                console.error(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setLogo(downloadURL);
                    props.handleLogoUpload(downloadURL);
                });
            }
        );
    }

    function uploadCompanionBanner(file) {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.toLocaleString('default', { month: 'long' });
        const filePath = `campaigns/${year}/${month}/${file.name}`;
        const storageRef = ref(storage, filePath);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                console.error(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setCompanionBanner(downloadURL);
                    props.handleCompanionBannerUpload(downloadURL);
                });
            }
        );
    }

    const shouldRenderCompletionAnimation = () => {
        const hasVideo = files.some(fileObj => fileObj.file.type.startsWith('video/') && fileObj.downloadURL);
        const hasAudio = files.some(fileObj => fileObj.file.type.startsWith('audio/') && fileObj.downloadURL);

        return (
            (hasVideo && logo) || (hasAudio && companionBanner)
        );
    };

    return (
        <div style={{ display: 'block', position: 'relative'}} key={props.cam}>
            {!initialUploadDone && (
                <div
                    className="main-upload"
                    onDragOver={handleDrop}
                    onDragLeave={() => { }}
                    onDrop={handleDrop}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (fileInputRef.current) {
                            fileInputRef.current.click(); 
                        }
                    }}
                    style={{ marginBottom: '16px' }}
                >
                    <label id="uploadLabel">Upload Creative Assets</label>
                    <input
                        id="fileInput"
                        type="file"
                        ref={fileInputRef}
                        accept=".mp4, .mp3, .wav"
                        multiple
                        onChange={handleChange}
                        style={{ display: 'none' }}
                    />
                </div>
            )}
            {error && (
                <div style={{ display: 'flex', alignContent: 'center', gap: '8px', marginTop: '10px' }}>
                    <img src={errorIcon} style={{ width: '24px' }} alt="error"></img>
                    <div className="error-msg">{error}</div>
                </div>
            )}
            <div className="uploaded-files" style={{marginTop:'0px'}}>
                {files.map((fileObj, index) => (
                    <div key={index} style={{ position: 'relative', marginBottom: '16px'}}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div className="closeXbtn" onClick={() => removeFile(fileObj.file, fileObj)}>×</div>
                            <p style={{ marginLeft: '8px', flex: 1, fontWeight:'bold' }}>{fileObj.file.name}</p>
                        </div>
                        {fileObj.downloadURL ? (
                            fileObj.file.type.startsWith('video/') ? (
                                <video src={fileObj.downloadURL} controls style={{ width: '100%', borderRadius: '8px' }}></video>
                            ) : (
                                <audio src={fileObj.downloadURL} controls style={{ width: '100%', borderRadius: '8px' }}></audio>
                            )
                        ) : fileObj.error ? (
                            <div style={{ color: 'red' }}>{fileObj.error}</div>
                        ) : (
                            <div style={{ position: 'relative', overflow: 'hidden', height: '4px', backgroundColor: 'var(--primary-color)', width: fileObj.progress + '%', borderRadius: '100px', marginTop: '8px' }}>
                                <div id="progressSheen"></div>
                                <p style={{ textAlign: 'center', fontSize: '12px', marginTop: '4px' }}>{Math.floor(fileObj.progress) + "%"}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {showUploadButton && (
                <div
                    className="upload-additional-creative"
                    onClick={() => additionalFileInputRef.current.click()}
                    style={{
                        backgroundColor: 'var(--primary-color)',
                        color: '#fff',
                        fontFamily: 'DM Sans',
                        fontWeight: 700,
                        width: 'auto',
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        padding: '10px 20px',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        marginTop: '16px',
                    }}
                >
                    Upload Additional Creative
                    <input
                        type="file"
                        ref={additionalFileInputRef}
                        accept=".mp4, .mp3, .wav"
                        multiple
                        onChange={handleChange}
                        style={{ display: 'none' }}
                    />
                </div>
            )}

            {(requireLogo || requireCompanionBanner) && (
                <div className="additional-assets" style={{ marginTop: '16px' }}>
                    <div style={{
                        fontFamily: 'DM Sans', fontSize: '16px', fontWeight: 700,
                    }}>Supporting Creative Assets (Required)</div>

                    {requireLogo && (
                        <div className="logo-upload-wrapper1">
                            <div style={{ display: 'block' }}>
                                <label htmlFor="logo-upload">Upload Logo</label>
                                <input
                                    type="file"
                                    id="logo-upload"
                                    accept="image/jpeg, image/png"
                                    onChange={handleLogoUpload}
                                />
                            </div>
                            {logoError && <div className="error">{logoError}</div>}
                        </div>
                    )}

                    {requireCompanionBanner && (
                        <div className="companion-banner-upload-wrapper">
                            <div style={{ display: 'block' }}>
                                <label htmlFor="companion-banner-upload">Upload Companion Banner</label>
                                <input
                                    type="file"
                                    id="companion-banner-upload"
                                    accept="image/jpeg, image/png"
                                    onChange={handleCompanionBannerUpload}
                                />
                                <p style={{ marginTop: '0px', fontFamily: 'DM Sans', fontSize: '12px', }}>300px x 250px (JPEG, PNG)</p>
                            </div>
                            {companionBannerError && <div className="error">{companionBannerError}</div>}
                        </div>
                    )}
                    {logo && requireLogo && (
                        <div>
                            <p>Logo:</p>
                            <img src={logo} style={{ width: '100px' }} alt="uploaded logo" />
                        </div>
                    )}
                    {companionBanner && requireCompanionBanner && (
                        <div>
                            <p>Banner:</p>
                            <img src={companionBanner} style={{ width: '300px' }} alt="uploaded banner" />
                        </div>
                    )}
                    <OTTRetargetingUpload ottEnabled={(i) => { props.ottEnabled(i) }} updateSupportingCreative={(i) => { props.updateSupportingCreative(i, props.camp_id) }} />
                </div>
            )}

            <div style={{ paddingTop: '20px' }}>
                <div onClick={() => setShowVideoRequirements(!showVideoRequirements)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                    <strong>Video Requirements</strong>
                    <span style={{ marginLeft: 'auto' }}>{showVideoRequirements ? '▲' : '▼'}</span>
                </div>
                {showVideoRequirements && (
                    <div>
                        <p>Minimum Resolution: 1920x1080</p>
                        <p>Maximum File Size: 500 MB</p>
                        <p>Duration: 12-33 seconds</p>
                        <p>*Note 15 or 30 second commercials get priority placement*</p>
                        <p>Bitrate: Minimum of 1.2 mbps</p>
                    </div>
                )}
                <div onClick={() => setShowAudioRequirements(!showAudioRequirements)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                    <strong>Audio Requirements</strong>
                    <span style={{ marginLeft: 'auto' }}>{showAudioRequirements ? '▲' : '▼'}</span>
                </div>
                {showAudioRequirements && (
                    <div>
                        <p>Duration: 12-33 seconds (30 Seconds is Highly Recommended)</p>
                        <p>Minimum Bitrate: 160 Kbps</p>
                    </div>
                )}
            </div>

            {shouldRenderCompletionAnimation() && (
                <div style={{ position: 'absolute', bottom: '0px', pointerEvents: 'none', width: '100%' }}>
                    <LottieAnimation animationData={confetti} height={'auto'} width={'100%'} />
                </div>
            )}
        </div>
    );
};

export default UploadCreative;
