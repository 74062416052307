import React, { useState, useEffect, useRef } from 'react';

function Location({ updateLocals }) {
    const [selectedLocals, setSelectedLocals] = useState([]);
    const [isNational, setIsNational] = useState(false);
    const inputRef = useRef(null); // Ref for the input element

    // Function to remove a location from selected locals
    const handleRemoveClick = (removeLocal) => {
        const newLocals = selectedLocals.filter(local => local.value !== removeLocal.value);
        setSelectedLocals(newLocals);
    };

    // Initialize Autocomplete when the component mounts
    useEffect(() => {
        if (window.google && window.google.maps && window.google.maps.places ) {
            const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
                types: ['(regions)'], // Restrict autocomplete to regions
                componentRestrictions: { country: 'us' },
            });

            // Listener for when a place is selected
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (place && place.formatted_address) {
                    const newLocal = { label: place.formatted_address, value: place.formatted_address };
                    
                    // Check for duplicates before adding to selectedLocals
                    setSelectedLocals((prevLocals) => {
                        const exists = prevLocals.some(local => local.value === newLocal.value);
                        if (!exists) {
                            return [...prevLocals, newLocal];
                        }
                        return prevLocals;
                    });

                    inputRef.current.value = ''; // Clear input after selection
                }
            });
        } else {
            console.error('Google Maps API is not loaded.');
        }
    }, []);

    // Update parent component when selectedLocals changes
    useEffect(() => {
        updateLocals('locals', selectedLocals);
    }, [selectedLocals]); // Only re-run when selectedLocals changes, removed updateLocals from dependencies

    return (
        <div>
            {/* Show the input field if the campaign is not set to National */}
            <div style={{ display: isNational ? 'none' : 'block' }}>
                <input
                    type="text"
                    ref={inputRef} // Attach ref to input element
                    style={{ width: '92%' }}
                    placeholder="Set targeting by city, state, county, or zipcode"
                />
            </div>

            <div style={{ display: 'inline-flex', justifyContent: 'flex-start', alignItems: 'center', gap: '6px' }}>
                <input
                    type="checkbox"
                    checked={isNational}
                    onChange={(e) => {
                        setIsNational(e.target.checked);
                        setSelectedLocals([]); // Clear locals when switching to national
                    }}
                />
                <p style={{ fontSize: '14px', fontWeight: 500 }}>Set to National Ad Campaign (USA)</p>
            </div>

            {/* Show selected local places */}
            <div style={{ display: isNational ? 'none' : 'block' }}>
                <div className="selected-options" style={{ marginTop: '14px' }}>
                    {selectedLocals.map((option) => (
                        <div className="selected-local-pill" key={option.value}>
                            <span>{option.label}</span>
                            <button className="x-btn" onClick={() => handleRemoveClick(option)}>X</button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Location;
