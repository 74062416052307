import React, { useEffect, useState } from 'react';
import './Deployments.css'; // External CSS file
import { db } from '../firebase/firebaseApp';
import { doc, getDoc } from 'firebase/firestore';
import sw_logo from "../Images/Streamwave_Blk.png"
const CampaignDetails = () => {
    const [data, setData] = useState(null); // Default to null
    const [loading, setLoading] = useState(true); // Loading state
    const [collapsedCampaigns, setCollapsedCampaigns] = useState({}); // State to manage collapsed campaigns
    const [passwordEntered, setPasswordEntered] = useState(false); // Password validation state
    const [password, setPassword] = useState(''); // State for password input
    const correctPassword = 'evolution'; // The required password

    useEffect(() => {
        const getCampaign = async (id) => {
            try {
                const docRef = doc(db, 'campaigns', id);
                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists()) {
                    const campaignData = docSnapshot.data();
                    setData(campaignData);
                    console.log(campaignData)
                } else {
                    console.log('Document not found');
                }
            } catch (error) {
                console.error('Error fetching campaign:', error);
            } finally {
                setLoading(false); // End loading once fetch is done
            }
        };

        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');
        if (id) {
            getCampaign(id);
        }
    }, []);

    // Function to toggle the collapse state of a campaign
    const toggleCollapse = (campaignId) => {
        setCollapsedCampaigns((prevState) => ({
            ...prevState,
            [campaignId]: !prevState[campaignId], // Toggle the collapse state
        }));
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === correctPassword) {
            setPasswordEntered(true);
        } else {
            alert("Incorrect password. Please try again.");
        }
    };

    const renderCreativeDownloadLinks = (creative) => {
        return creative.map((item, index) => {
            let fileType = item.type.includes('image') ? 'Image' : item.type.includes('video') ? 'Video' : 'Audio';

            return (
                <div key={index} className="campaign-details-creative-item">
                    <h2>Creative</h2>
                    <p>{"Main " + fileType}:</p>

                    {fileType === 'Video' && <video className="campaign-details-creative-video" controls src={item.url} />}
                    {fileType === 'Image' && <img className="campaign-details-creative-image" src={item.url} alt={`Creative ${index}`} />}
                    {fileType === 'Audio' && <audio className="campaign-details-creative-audio" controls src={item.url} />}
                    <a href={item.url} download={`creative-${index}`} target="_blank" rel="noopener noreferrer">
                        Download {fileType}
                    </a>
                </div>
            );
        });
    };

    const renderCampaigns = (campaigns) => {
        if (!campaigns || campaigns.length === 0) return <p>No campaigns available.</p>;

        return campaigns.map((campaign) => {
            const isCollapsed = collapsedCampaigns[campaign.id] || false;

            return (
                <div className="campaign-details-container" key={campaign.id} style={{ marginBottom: '20px' }}>
                    <div className="campaign-details-header" onClick={() => toggleCollapse(campaign.id)}>
                        <h4 className="campaign-details-title">
                            Campaign Name: {campaign.details.campaign_name}
                            <span className="campaign-details-toggle-arrow">{isCollapsed ? '▼' : '▲'}</span>
                        </h4>
                    </div>

                    {!isCollapsed && (
                        <div className="campaign-details-info">
                            <div className="campaign-details-row">
                                <span className="campaign-details-label">Campaign Budget Before Fees (For reference only):</span> <span className="campaign-details-value">${campaign.budget}</span>
                            </div>
                            <div className="campaign-details-row">
                                <span className="campaign-details-label" style={{ fontWeight: 'bold' }}>Campaign Budget After Fees (Net Spend for the campaign):</span> <span className="campaign-details-value" style={{ fontWeight: 'bold', fontSize: '22px' }}>${campaign.campaginSpendAfterFees}</span>
                            </div>
                            {campaign.supportingCreative.length === 4 ?
                                <div className="campaign-details-row">
                                    <span className="campaign-details-label" style={{ fontWeight: 'bold' }}>OTT Enabled (Allocate 10% of campaign budget to OTT)</span> <span className="campaign-details-value" style={{ fontWeight: 'bold', fontSize: '22px' }}>${campaign.campaginSpendAfterFees * .1}</span>
                                </div> : <div className="campaign-details-row">
                                    <span className="campaign-details-label">OTT Disabled</span> <span className="campaign-details-value">${0}</span>
                                </div>
                            }


                            <div className="campaign-details-row">
                                <span className="campaign-details-label">Start Date:</span> <span className="campaign-details-value">{campaign.details.campagin_start_date}</span>
                            </div>
                            <div className="campaign-details-row">
                                <span className="campaign-details-label">End Date:</span> <span className="campaign-details-value">{campaign.details.campagin_end_date}</span>
                            </div>
                            <div className="campaign-details-row">
                                <span className="campaign-details-label">Company Name:</span> <span className="campaign-details-value">{campaign.details.company_name}</span>
                            </div>
                            <div className="campaign-details-row">
                                <span className="campaign-details-label">Company Website:</span> <span className="campaign-details-value">{campaign.details.company_website}</span>
                            </div>
                            <div className="campaign-details-row">
                                <span className="campaign-details-label">Business Address:</span> <span className="campaign-details-value">{campaign.details.business_address}</span>
                            </div>
                            <div className="campaign-details-row">
                                <span className="campaign-details-label">Landing Page:</span> <span className="campaign-details-value">{campaign.details.campaign_landing_page}</span>
                            </div>
                            {campaign.creative && campaign.creative.length > 0 && renderCreativeDownloadLinks(campaign.creative)}

                            {campaign.details.company_logo && (
                                <div>
                                    <h3>Logo:</h3>
                                    <img className="campaign-details-creative-image" src={campaign.details.company_logo} alt="Company Logo" />
                                </div>
                            )}

                            {campaign.supportingCreative.length === 4 && (
                                <div>
                                    <h3>OTT Creative</h3>
                                    {campaign.supportingCreative.map((asset) => (
                                        <img className="campaign-details-creative-image" src={asset.url} alt={asset.id} key={asset.id} />
                                    ))}
                                </div>
                            )}

                            <div className="campaign-details-subsection">
                                <h3>Targeting</h3>
                                <div className="campaign-details-row">
                                    <span className="campaign-details-label">OEM:</span>
                                    <ul className="campaign-details-list">
                                        {campaign.targeting.industries.map((industry, index) => (
                                            <li key={index}>
                                                <a href={industry.value} target="_blank" rel="noopener noreferrer">{industry.label}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="campaign-details-row">
                                    <span className="campaign-details-label">Local Targeting:</span>
                                    <ul className="campaign-details-list">
                                        {campaign.targeting.locals.map((local, index) => (
                                            <li key={index}>{local.label}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    <span className="campaign-details-label">Audiences:</span>
                                    <div className="campaign-details-list">
                                        {campaign.targeting.audiences.map((audience, index) => (
                                            <div className="audience" key={index}>{audience}</div>
                                        ))}
                                    </div>
                                </div>
                                <div className="campaign-details-row">
                                    <span className="campaign-details-label">Additional Campaign Objectives:</span> <span className="campaign-details-value">{campaign.targeting.objective || "No objective specified"}</span>
                                </div>
                            </div>
                            <div className="campaign-details-subsection">
                                <h3>Contact Info</h3>
                                <p>{campaign.contact.first_name} {campaign.contact.last_name}</p>
                                <p>Email: {campaign.contact.email}</p>
                                <p>Phone: {campaign.contact.phone}</p>
                            </div>
                        </div>
                    )}
                </div>
            );
        });
    };

    if (!passwordEntered) {
        return (
            <div id="campaign-details-password-container">
                <img src={sw_logo} style={{ width: '320px' }}></img>
                <form onSubmit={handlePasswordSubmit}>
                    <input
                        type="password"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit">Submit</button>
                </form>
            </div>
        );
    }

    if (loading) {
        return <p>Loading campaign data...</p>;
    }

    if (!data) {
        return <p>Campaign data not found.</p>;
    }

    const formatDate = (submissionDate) => {
        const milliseconds = submissionDate.seconds * 1000 + submissionDate.nanoseconds / 1000000;
        const date = new Date(milliseconds);
        
        // Format date to mm/dd/yyyy
        const formattedDate = (date.getMonth() + 1).toString().padStart(2, '0') + '/' +
                              date.getDate().toString().padStart(2, '0') + '/' +
                              date.getFullYear();
        
        return formattedDate;
      };

    return (
        <div id="campaign-details-container" >
            <div id="campaign-details-content">
                <img src={sw_logo} style={{ width: '320px', margin: 'auto', textAlign: 'center' }}></img>
                <h2 id="campaign-details-title">New Streamwave Campaign(s) Ready for Deployment!</h2>
                <p>{"Deployment id:"+data.documentId}</p>
                <a href={"/invoice?id="+data.documentId}>Customer Invoice</a>
                <p id="campaign-details-description">{"If you have any questions, please contact betsy@aetautomotive.com or jeff@aetautomotive.com"}</p>
                <div className="campaign-details-section">
                    <h3 className="campaign-details-section-title">Deployment Logistics</h3>
                    <div className="campaign-details-row">
                        <span className="campaign-details-label">Date Submitted:</span> <span className="campaign-details-value">{formatDate(data.submissionDate)}</span>
                    </div>
                    <div className="campaign-details-row">
                        <span className="campaign-details-label">Total Deploymend Spend (Across all campaigns):</span> <span className="campaign-details-value">${data.totalSpend || 0}</span>
                    </div>
                    <div className="campaign-details-row">
                        <span className="campaign-details-label">Streamwave Net (23% of spend):</span> <span className="campaign-details-value">${data.streamwave_net || 0}</span>
                    </div>

                    <div className="campaign-details-row">
                        <span className="campaign-details-label" style={{ fontWeight: 'bold' }}>Total Spend After Fees (Across all campaigns sent to Evo):</span> <span className="campaign-details-value" style={{ fontWeight: 'bold', fontSize: '22px' }}>${data.totalSpendAfterFees || 0}</span>
                    </div>
                    <div className="campaign-details-row">
                        <span className="campaign-details-label">Partner:</span> <span className="campaign-details-value">{data.partner || "Unknown"}</span>
                    </div>
                    <div className="campaign-details-row">
                        <span className="campaign-details-label">Partner Commission (60% of Streameave Net):</span> <span className="campaign-details-value">${data.partner_commission || 0}</span>
                    </div>
                    <div className="campaign-details-row">
                        <span className="campaign-details-label">Streamwave Commission (40% of Streamwave Net):</span> <span className="campaign-details-value">${data.streamwave_commission || 0}</span>
                    </div>
                    <div className="campaign-details-row">
                        <span className="campaign-details-label">Processing Fees (Shared Between Streamwave and Partner):</span> <span className="campaign-details-value">${data.processing_fees || 0}</span>
                    </div>
                    <div className="campaign-details-row">
                        <span className="campaign-details-label">Payment Status:</span> <span className="campaign-details-value">{data.payment_status || "Pending"}</span>
                    </div>
                    <div className="campaign-details-row">
                        <span className="campaign-details-label">AET Account Name:</span> <span className="campaign-details-value">{data.aet_account_name || "N/A"}</span>
                    </div>
                    <div className="campaign-details-row">
                        <span className="campaign-details-label">AET Account ID:</span> <span className="campaign-details-value">{data.aet_account_id || "N/A"}</span>
                    </div>
                  
                   
                </div>

                <div className="campaign-details-section">
                    <h3 className="campaign-details-section-title">Campaigns</h3>
                    {renderCampaigns(data.campaigns)}
                </div>
            </div>
        </div>
    );
};

export default CampaignDetails;
