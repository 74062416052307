import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import AdCampaign from './ViewCampaign/ViewCampaign.js';
import Deployments from './ViewCampaign/Deployments.js';
import Create from './Create/Create';
import AET from './Create/AETAUTO';
import CampaignBuilder from './Create/CampaignWrapper.js'
import AETAccount from './Partners/aetAccounts';
import SWAccount from './Partners/streamwaveAccounts'
import ThankYou from './ThankYou/ThankYou';
import AdVoice from './AdVoice/AdVoice';
import InvoicePage from './Invoice/InvoicePage.js';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/launchad" element={<Create />} />
          <Route exact path="/partnerships/iheart-radio" element={<AdVoice assistant_id="asst_SaTnnDryEBvgjqFg9Mtyz7xt" name="Russ Darrow Mazda"/>} />
          <Route exact path="/partnerships/aet-automotive" element={<AET/>} />
          <Route exact path="/partnerships/aet-automotive2" element={<CampaignBuilder/>} />
          <Route exact path="/thankyou" element={<ThankYou />} />
          <Route exact path="/aet" element={<AETAccount />} />
          <Route exact path="/sw" element={<SWAccount />} />
          <Route exact path="/campaigns" element={<AdCampaign />} />
          <Route exact path="/deployments" element={<Deployments />} />
          <Route exact path="/invoice" element={<InvoicePage/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;