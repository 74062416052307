import React, { useEffect, useState } from 'react';
import './InvoicePage.css'; // Import the CSS file for styling
import { db } from '../firebase/firebaseApp';
import { doc, getDoc } from 'firebase/firestore';
import sw_logo from "../Images/Streamwave_Blk.png";
import * as html2pdf from 'html2pdf.js';

const InvoicePage = () => {
    const [data, setData] = useState(null); // Default to null
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const getCampaign = async (id) => {
            try {
                const docRef = doc(db, 'campaigns', id);
                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists()) {
                    const campaignData = docSnapshot.data();
                    setData(campaignData);
                } else {
                    console.log('Document not found');
                }
            } catch (error) {
                console.error('Error fetching campaign:', error);
            } finally {
                setLoading(false); // End loading once fetch is done
            }
        };

        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');
        if (id) {
            getCampaign(id);
        }
    }, []);

    // Function to download the invoice as a PDF
    const downloadPDF = () => {
        const element = document.getElementById('invoice'); // The invoice container
        const options = {
            margin: 0.5,
            filename: 'invoice.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        html2pdf().from(element).set(options).save();
    };

    if (loading) {
        return <div className="invoice-loading">Loading your invoice...</div>;
    }

    if (!data) {
        return <div className="invoice-error">No invoice data found.</div>;
    }

    // Format the submission date from the timestamp
    const submissionDate = new Date(data.submissionDate.seconds * 1000);

    // Function to calculate adjusted budget and OTT retargeting
    const calculateBudget = (campaign) => {
        let budget = parseFloat(campaign.budget);
        let ottRetargeting = 0;
        if (campaign.supportingCreative.length === 4) {
            ottRetargeting = budget * 0.1; // 10% of the campaign budget for OTT retargeting
            budget = budget * 0.9; // Reduce the campaign budget by 10%
        }
        return { adjustedBudget: budget, ottRetargeting };
    };

    return (
        <div className="invoice-page">
              <button className="invoice-download-button" style={{backgroundColor:'var(--brand-color)'}} onClick={downloadPDF}>Download PDF</button>
            <div id="invoice" className="invoice-container">
                {/* Header */}
                <div className="invoice-header">
                    <img src={sw_logo} alt="Streamwave Logo" className="invoice-logo" />
                    <h1>Invoice</h1>
                    <p className="invoice-date">Date: {submissionDate.toDateString()}</p>
                    <p className="invoice-number"><strong>Invoice #:</strong> {data.documentId}</p>
                </div>

                {/* Business Info (AET Automotive) */}
                <div className="invoice-business-info">
                    <h2>From</h2>
                    <p><strong>AET Automotive</strong></p>
                    <p>6073 W 44th Ave #100</p>
                    <p>Wheat Ridge, CO 80033</p>
                    <p><strong>Phone:</strong> (720) 663-0239</p>
                </div>

                {/* Customer Billing Info */}
                <div className="invoice-contact">
                    <h2>Bill To</h2>
                    <p><strong>Name:</strong> {data.campaigns[0].contact.first_name} {data.campaigns[0].contact.last_name}</p>
                    <p><strong>Email:</strong> {data.campaigns[0].contact.email}</p>
                    <p><strong>Phone:</strong> {data.campaigns[0].contact.phone}</p>
                </div>

                {/* Campaigns */}
                <div className="invoice-campaigns">
                    <h2>Campaigns</h2>
                    <table className="invoice-table">
                        <thead>
                            <tr>
                                <th>Campaign Name</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Company</th>
                                <th>Budget</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.campaigns.map((campaign, index) => {
                                const { adjustedBudget, ottRetargeting } = calculateBudget(campaign);

                                return (
                                    <React.Fragment key={index}>
                                        <tr>
                                             <td>{`CTV Campaign - (${campaign.details.campaign_name})`}</td>
                                            <td>{campaign.details.campagin_start_date}</td>
                                            <td>{campaign.details.campagin_end_date}</td>
                                            <td>{campaign.details.company_name}</td>
                                            <td>${adjustedBudget.toFixed(2)}</td>
                                        </tr>
                                        {/* Add OTT Retargeting Line Item if applicable */}
                                        {ottRetargeting > 0 && (
                                            <tr>
                                                <td>{`OTT Retargeting - (${campaign.details.campaign_name})`}</td>
                                                <td>{campaign.details.campagin_start_date}</td>
                                                <td>{campaign.details.campagin_end_date}</td>
                                                <td>{campaign.details.company_name}</td>
                                                <td>${ottRetargeting.toFixed(2)}</td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                {/* Only Total Spend */}
                <div className="invoice-total">
                    <h2>Total Spend</h2>
                    <p><strong>Total Spend:</strong> ${data.totalSpend}</p>
                </div>

                {/* Footer */}
                <div className="invoice-footer">
                    <p>Thank you for your business!</p>
                    <p><strong>Payment Status:</strong> Paid</p>
                </div>
            </div>

            {/* PDF Download Button */}
          
        </div>
    );
};

export default InvoicePage;
