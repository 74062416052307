import React, { useState, useCallback, useEffect } from 'react';
import { collection, addDoc, updateDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseApp.js';
import { v4 as uuidv4 } from 'uuid'; // Import uuid for unique IDs
import left from './left.png';
import right from './right.png';
import playIcon from './playIcon.png';
import warning from '../Images/warning.svg';
import AdVoice from '../AdVoice/AdVoice.js';
import { FaRegTrashAlt } from "react-icons/fa";
import LottieAnimation from '../Lottie/Lottie.js';
import Campaign from './Campaign.js'; // Assuming this renders the campaign form
import './create.css';
import logo from '../AdVoice/aeet.png';
import CheckoutBanner from './CheckoutBanner.js';
import checkmarkAnim from "../Lottie/59945-success-confetti.json";
import addIcon from "../Images/add.svg"

function CampaignBuilder() {
    const [errorMsg, setErrorMsg] = useState(null);
    const [activeToggle, setActiveToggle] = useState("video");
    const [aetAccountId, setAetAccountId] = useState(null)
    const [aetAccountName, setAetAccountName] = useState(null)
    const [proceedToPayment, setProceedToPayment] = useState(false);
    const [campaigns, setCampaigns] = useState([{ id: uuidv4(), budget: 0, creativeReady: false }]); // Minimal campaign data


    // Function to add a new campaign with a unique uuid
    const addNewCampaign = () => {
        const newCampaign = {
            id: uuidv4(),
            budget: 0,
            creativeReady: false
        };
        console.log('here', prevCampaigns => [...prevCampaigns, newCampaign])
        // Append new campaign to the existing list without modifying other campaigns
        setCampaigns(prevCampaigns => [...prevCampaigns, newCampaign]);
    };


    // Function to remove a campaign by ID
    const removeCampaign = (id) => {
        const updatedCampaigns = campaigns.filter(campaign => campaign.id !== id);
        setCampaigns(updatedCampaigns);
    };


    useEffect(()=>{
        const url = new URL(window.location);
        const aet_account_id = url.searchParams.get('aet_account_id');
        const aet_account_name = url.searchParams.get('aet_account_name');
        if (aet_account_id) {
            setAetAccountId(aet_account_id)
        }

        if (aet_account_name) {
            setAetAccountName(aet_account_name)
        }
    },[])


    // Handle updates for detailed campaign data, storing it directly in campaigns
    // const handleCampaignUpdates = useCallback((id, newData) => {
    //     const updatedCampaigns = campaigns.map(campaign =>
    //         campaign.id === id ? { ...campaign, ...newData } : campaign
    //     );
    //     setCampaigns(updatedCampaigns);
    // }, [campaigns]);

    const handleCampaignUpdates = useCallback((id, newData) => {
        const updatedCampaigns = campaigns.map(campaign => {
            // Check if this is the campaign that needs to be updated
            if (campaign.id === id) {
                // Merge new data with the existing campaign
                const updatedCampaign = { ...campaign, ...newData };
    
                // Check if the campaign meets the conditions to be marked as ready
                if (updatedCampaign.creativeReady 
                    && updatedCampaign.targeting.isComplete 
                    && updatedCampaign.details.isComplete 
                    && updatedCampaign.budget >= 500) {
                    updatedCampaign.isReady = true;
                } else {
                    updatedCampaign.isReady = false;
                }
    
                return updatedCampaign;
            }
    
            // If it's not the campaign we're updating, return it as-is
            return campaign;
        });
    
        setCampaigns(updatedCampaigns);
    }, [campaigns]);
    

    const creativeComplete=(isComplete, id)=>{
        let camps=[...campaigns]
        camps.forEach((campaign)=>{
            if(document.getElementById("lottie"+id)){
                campaign.creativeReady=true
            }else{
                campaign.creativeReady=false
            }
        })
        setCampaigns(camps)
    }


    const createAdCampagin = (adSpend) => {
        let adOrder = {}
        adOrder.description = "Thank you for choosing AET Automotive in partnership with Streamwave.io as your CTV advertising partner! We greatly value your business. Once your payment is processed, our team will immediately start building your campaign. Rest assured, we'll keep you updated every step of the way. As soon as your campaign is live, we'll send you an email notification confirming its launch.";
        adOrder.totalSpend=adSpend;
        adOrder.streamwave_net=parseFloat((adOrder.totalSpend*.23).toFixed(2));
        adOrder.processing_fees = parseFloat((adOrder.totalSpend * 0.029 + 0.3).toFixed(2));
        adOrder.totalSpendAfterFees=parseFloat((adOrder.totalSpend-adOrder.streamwave_net).toFixed(2));
        adOrder.submissionDate = new Date();
        adOrder.partner = "AET Automotive";
        adOrder.partner_commission = parseFloat(((adOrder.streamwave_net - adOrder.processing_fees) * .6).toFixed(2))
        adOrder.streamwave_commission = parseFloat(((adOrder.streamwave_net - adOrder.processing_fees) * .4).toFixed(2))
        adOrder.payment_status = "Pending"
        adOrder.aet_account_id = aetAccountId
        adOrder.aet_account_name = aetAccountName
        adOrder.version=2
        let camps=[...campaigns]
        camps.forEach((camp)=>{
            camp.campaginSpendAfterFees=parseFloat((camp.budget*.77).toFixed(2))
        })
        adOrder.campaigns=camps
        console.log(adOrder)
        sendToDB(adOrder)
    }

    const sendToDB = async (adOrder) => {
        setProceedToPayment(true)

        try {
            const docRef = doc(collection(db, 'campaigns'));
            await setDoc(docRef, { ...adOrder, documentId: docRef.id });
            adOrder.documentId = docRef.id
            console.log(adOrder)
            const url = new URL(window.location);
            const isTest = url.searchParams.get('test');
            if (isTest) {
                createStripeTestPaymentSession(adOrder)
            } else {
                createStripePaymentSession(adOrder)
            }


        } catch (error) {
            console.error('Error creating document:', error);
            // Handle error if needed
        }
    };


    // const sendToDB = async (adOrder) => {
    //     setProceedToPayment(true)
    //     let order = {};
    //     order.campaign = createAdCampagin();
    //     order.description = "Thank you for choosing AET Automotive in partnership with Streamwave.io as your CTV advertising partner! We greatly value your business. Once your payment is processed, our team will immediately start building your campaign. Rest assured, we'll keep you updated every step of the way. As soon as your campaign is live, we'll send you an email notification confirming its launch."
    //     order.gross_spend = parseInt(budget.budget);
    //     order.processing_fees = parseFloat((order.gross_spend * 0.029 + 0.3).toFixed(2));
    //     order.streamwave_net = parseFloat(((order.gross_spend * 0.23)).toFixed(2));
    //     order.net_spend = parseFloat((order.gross_spend - order.streamwave_net).toFixed(2));
    //     order.submissionDate = new Date();
    //     order.campaign_status = "Waiting for Payment";
    //     order.partner = "AET Automotive";
    //     order.partner_commission = (order.streamwave_net - order.processing_fees) * .6
    //     order.streamwave_commission = (order.streamwave_net - order.processing_fees) * .4
    //     order.aet_account_id = aetAccountId
    //     order.aet_account_name = aetAccountName
    //     order.invoiceDetails = invoiceDetails

    //     order.payment_status = "Pending"

        // try {
        //     const docRef = doc(collection(db, 'campaigns'));
        //     await setDoc(docRef, { ...order, documentId: docRef.id });
        //     order.documentId = docRef.id
        //     const url = new URL(window.location);
        //     const isTest = url.searchParams.get('test');
        //     if (isTest) {
        //         createStripeTestPaymentSession(order)
        //     } else {
        //         createStripePaymentSession(order)
        //     }


        // } catch (error) {
        //     console.error('Error creating document:', error);
        //     // Handle error if needed
        // }
    // };



    const createStripePaymentSession = async (order) => {
        try {
            const response = await fetch('https://aet-streamwave-backend-bhamlefty.replit.app/api/create-payment-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(order),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const { sessionId, checkoutUrl } = await response.json();
            //console.log(sessionId, checkoutUrl)
            window.open(checkoutUrl)

            setProceedToPayment(false)
        } catch (error) {
            console.error('Error creating payment session:', error);
        }
    };

    const createStripeTestPaymentSession = async (order) => {
        try {
            const response = await fetch('https://aet-streamwave-backend-bhamlefty.replit.app/api/create-test-payment-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(order),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const { sessionId, checkoutUrl } = await response.json();
            //console.log(sessionId, checkoutUrl)
            window.open(checkoutUrl)

            setProceedToPayment(false)
        } catch (error) {
            console.error('Error creating payment session:', error);
        }
    };

    return (
        <div id="master-grid" style={{ marginTop: '26px' }}>
            <img src={left} alt="left" style={{ width: '100%', top: '40px', left: '0px' }} />

            <div id="create-container-new">
                {activeToggle === "video" && (
                    <AdVoice
                        assistant_id="asst_x2eFu3RvIsrG10Nn1CfRug7B"
                        name="Generate Voiceovers"
                        logo={logo}
                        headerColor="#00a375"
                    />
                )}

                <div style={{ margin: 'auto' }}>
                    <h1 style={{ textAlign: 'center', marginBottom: '0px', fontSize: '30px' }}>
                        Advertise Across 40+ Premium Networks
                    </h1>
                    <h3
                        style={{
                            textAlign: 'center',
                            marginTop: '0px',
                            fontWeight: 500,
                            marginBottom: '14px',
                        }}
                    >
                        Reach your customers, <strong style={{ color: 'var(--brand-color)' }}>whenever</strong> and{' '}
                        <strong style={{ color: 'var(--brand-color)' }}>wherever</strong> they are streaming
                    </h3>
                    <div
                        style={{
                            display: 'flex',
                            margin: 'auto',
                            gap: '8px',
                            width: 'fit-content',
                            borderRadius: '30px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginBottom: '18px',
                            border: '2px solid #004432',
                        }}
                        onClick={() => {
                            window.open('https://share.vidyard.com/watch/Jx6bXMfvMsKLU9GayRp8gP', '_blank');
                        }}
                    >
                        <img style={{ width: '20px' }} src={playIcon} alt="playIcon"></img>
                        <div style={{ color: '#004432', fontFamily: 'DM Sans', fontWeight: 600 }}>How it works</div>
                    </div>
                </div>

                {/* Render the campaigns dynamically */}
                {campaigns.map((campaign, key) => (
                    <div
                        key={key}
                        id={campaign.id}
                        style={{
                            backgroundColor: 'white',
                            position: 'relative',
                            width: '530px',
                            margin: 'auto',
                            padding: '16px',
                            borderRadius: '20px',
                            marginBottom: '20px',
                            border: '1px solid lightgrey',
                        }}
                    >
                        <h4 style={{ margin: 0 }}>Campaign #{key+1}</h4>

                        <div
                            style={{
                                marginTop: '0px',
                                display: 'flex',
                                height: '60px',
                                position: 'absolute',
                                right: '18px',
                                top: '0px',
                            }}
                        >
                            <button
                                onClick={() => removeCampaign(campaign.id)}
                                style={{
                                    backgroundColor: 'transparent',
                                    color: 'black',
                                    border: 'none',
                                    padding: '10px',
                                    cursor: 'pointer',
                                    fontSize: '16px', // Adjust the size of the icon
                                }}
                            >
                                <FaRegTrashAlt />
                            </button>
                            {campaign.isReady ? (
                                <LottieAnimation
                                    animationData={checkmarkAnim}
                                    height={'60px'}
                                    width={'60px'}
                                />
                            ) : null}
                        </div>
                        <Campaign
                            key={key}
                            camp_id={campaign.id}
                            creativeComplete={creativeComplete}
                            handleCampaignUpdates={handleCampaignUpdates}
                            campaignData={campaign} // Pass current campaign data
                        />
                        
                    </div>
                ))}

                {/* Button to add a new campaign */}
                <button
                    id="newCampaignBtn"
                    onClick={addNewCampaign}
                    style={{
                        display: 'flex',
                        margin: 'auto',
                        alignItems: 'center',
                        marginTop: '20px',
                        fontFamily: 'DM Sans',
                        fontSize: '16px',
                        fontWeight: 600,
                        borderRadius: '20px',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        backgroundColor: 'var(--brand-color)',
                        marginBottom:'60px',
                        color: 'white',
                    }}
                > <img src={addIcon} alt='addIcon.png'></img>
                    Add Campaign
                </button>
                <CheckoutBanner campaigns={campaigns} createAdCampagin={createAdCampagin}/>

                {errorMsg && (
                    <div className="error-banner">
                        <div style={{ display: 'flex' }}>
                            <img src={warning} style={{ width: '22px', marginRight: '10px' }} alt="warning" />
                            <div>{errorMsg}</div>
                        </div>
                    </div>
                )}
            </div>

            <img src={right} alt="right" style={{ width: '100%', top: '40px', right: '0px' }} />

            {/* Checkout Banner at the bottom */}
            
        </div>
    );
}

export default CampaignBuilder;
