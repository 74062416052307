import React, { useState, useRef, useEffect } from 'react';
import styles from './audiofilters.module.scss';
import { FaRegPlayCircle } from "react-icons/fa/index.esm.js";

const AudioFilters = ({ voiceData, getSelectedVoice }) => {
    const [languageFilter, setLanguageFilter] = useState('');
    const [genderFilter, setGenderFilter] = useState('');
    const [selectedVoice, setSelectedVoice] = useState({
        "id": "s3://voice-cloning-zero-shot/1e64a221-3b48-48bb-bf5e-f9f656275979/jeff-gonzales/manifest.json",
        "name": "Jeff Gonzales",
        "type": "instant",
        "gender": "male",
        "voice_engine": "PlayHT2.0"
    },
);
    const [sampleUrl, setSampleUrl] = useState('');
    const [refreshAudio, setRefreshAudio] = useState(false)
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const audioRef = useRef(new Audio());

    const filteredVoices = voiceData.filter(
        (voice) =>
            (languageFilter === '' || voice.language === languageFilter) &&
            (genderFilter === '' || voice.gender === genderFilter)
    );

    useEffect(() => {
        setRefreshAudio(true);

        // Set refreshAudio to false after a delay (300ms)
        const timeoutId = setTimeout(() => {
            setRefreshAudio(false);
        }, 300);

        // Clean up the timeout to avoid memory leaks
        return () => clearTimeout(timeoutId);

    }, [sampleUrl]);



    const createSampleAudio = (voice) => {
        setSampleUrl(voice.sampleUrl);
    };

    const handleOptionSelect = (option) => {
        setSelectedVoice(option);
        getSelectedVoice(option)
        setDropdownOpen(false);
    };

    const handleDropdownToggle = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.dropdown}>
                    <select onChange={(e) => setLanguageFilter(e.target.value)} className={styles.landSelect}>
                        <option value="">All Languages</option>
                        {[...new Set(voiceData.map((voice) => voice.language))].map((language) => (
                            <option key={language} value={language}>
                                {language}
                            </option>
                        ))}
                    </select>
                </div>

                <div className={styles.dropdown}>
                    <select onChange={(e) => setGenderFilter(e.target.value)} className={styles.genderSelect}>
                        <option value="">All Genders</option>
                        {[...new Set(voiceData.map((voice) => voice.gender))].map((gender) => (
                            <option key={gender} value={gender}>
                                {gender}
                            </option>
                        ))}
                    </select>
                </div>



                {sampleUrl !== '' && !refreshAudio ? (
                    <audio style={{ visibility: 'hidden', height: '0px' }} autoPlay controls ref={audioRef}>
                        <source src={sampleUrl} type="audio/mpeg" />
                    </audio>
                ) : null}

            </div>

            <div className={styles.customDropdown}>
                <div className={styles.optionsContainer}>
                    <div className={styles.selectedVoice} onClick={handleDropdownToggle}>
                        {"Voice Talent: "+ selectedVoice.name}
                        <span className={styles.dropdownIcon}>{isDropdownOpen ? '▲' : '▼'}</span>
                    </div>
                    {isDropdownOpen && (
                        <div className={styles.dropdownContent}>
                            {filteredVoices.map((voice) => (
                                <div
                                    key={voice.uuid}
                                    className={`${styles.option} ${selectedVoice === voice.id ? styles.selected : ''}`}
                                    onClick={() => handleOptionSelect(voice)}
                                >
                                    <span>{voice.name}</span>
                                    <div className={styles.playBtn} onClick={(e) => { e.stopPropagation(); createSampleAudio(voice); }}>
                                        <FaRegPlayCircle />
                                    </div>

                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AudioFilters;
