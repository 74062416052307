import React, { useState, useEffect, useCallback, useRef } from 'react';
import ConvertToCSV from './CSV/csvConvert.js'
import LottieAnimation from '../Lottie/Lottie.js';
import { addDays, differenceInDays } from 'date-fns';
import Location from '../Location/Location.js';
import UploadVideo from '../Upload/UploadVideo.js';
import UploadCreative from '../Upload/UploadCreative.js';
import UploadAudio from '../Upload/UploadAudio.js';
import Industries from '../Industies/Industries.js';
import DateRangeModal from './DatePicker/DatePicker.js';
import brandTop from '../Images/brandtop.png';
import brandBottom from '../Images/brand_bottom.png';
import Logo from '../Images/Streamwave_Blk.png';
import chevron from '../Images/chevron_down.svg';
import finished from '../Images/done.svg';
import playIcon from './playIcon.png';
import warning from '../Images/warning.svg';
import checkmarkAnim from "../Lottie/59945-success-confetti.json";
// import PaymentForm from "../Payment/Payment"
import UploadLogo from '../Upload/UploadLogo.js';
import help from '../Images/help.svg';
import './create.css';
import logo from '../AdVoice/aeet.png';
function Campaign(props) {
    const isInitialMount = useRef(true);

    const [errorMsg, setErrorMsg] = useState(null);
    const [showAdVoice, setShowAdVoice] = useState(false);
    const [proceedToPayment, setProceedToPayment] = useState(false);
    const [activeToggle, setActiveToggle] = useState("video");
    const [referralId, setReferralId] = useState(null);
    const [isSplit, setIsSplit] = useState(false);
    const [activeCampaign, setActiveCampaign] = useState(null);
    const [aetAccountId, setAetAccountId] = useState(null)
    const [invoiceDetails, setInvoiceDetails] = useState(null)
    const [aetAccountName, setAetAccountName] = useState(null)
    const [expandUpload, setExpandUpload] = useState(true);
    const [ottEnabled, setOttEnabled] = useState(false);
    const [expandTargeting, setExpandTargeting] = useState(false);
    const [expandCampaign, setExpandCampaign] = useState(false);
    const [expandPayment, setExpandPayment] = useState(false);
    const [expandContact, setExpandContact] = useState(false);
    const [step1Complete, setStep1Complete] = useState(false);
    const [step2Complete, setStep2Complete] = useState(false);
    const [step3Complete, setStep3Complete] = useState(false);
    const [step4Complete, setStep4Complete] = useState(false);
    const [step5Complete, setStep5Complete] = useState(false);
    const [creative, setCreative] = useState([]);
    const [supportingCreative, setSupportingCreative] = useState([]);
    const [campagin, setCampaign] = useState({
        campaign_name: null,
        company_website: null,
        campaign_landing_page: null,
        company_name: null,
        business_address: null,
        campagin_start_date: null,
        campagin_end_date: null,
        campagin_duration: 30,
        isComplete: false
    });
    const [contact, setContact] = useState({
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        isComplete: false
    });
    const [targeting, setTargeting] = useState({
        industries: null,
        locals: null,
        audiences: null,
        objective: null,
        isComplete: false
    });
    const [budget, setBudget] = useState({
        budget: 0,
        isComplete: true,
    });

    const stableCampId = useRef(null);

    useEffect(()=>{
        if(step1Complete){
            props.creativeComplete(step1Complete, props.camp_id)
        }
    }, [step1Complete])

  

    useEffect(() => {
        const url = new URL(window.location);
        document.documentElement.style.setProperty('--primary-color', '#00A375');
        document.documentElement.style.setProperty('--brand-color', 'rgb(0, 68, 50)');
        //contact
        const contact_first_name = url.searchParams.get('contact_first_name');
        const contact_last_name = url.searchParams.get('contact_last_name');
        const contact_phone = url.searchParams.get('contact_phone');
        const contact_email = url.searchParams.get('contact_email');
       

        if (contact_first_name) {
            setContact({
                first_name: contact_first_name,
                last_name: contact_last_name,
                phone: contact_phone,
                email: contact_email
            })
        }

        const referralIdParam = url.searchParams.get('referral_id');
        const splitParam = url.searchParams.get('split');

        if (referralIdParam) {
            setReferralId(referralIdParam)
        }
        if (splitParam) {
            setIsSplit(true)
        }
    }, []);

    useEffect(() => {
        let campaginData = {}
        campaginData.creative = creative
        campaginData.supportingCreative = supportingCreative
        campaginData.details = campagin
        campaginData.contact = contact
        campaginData.targeting = targeting
        campaginData.budget = budget.budget
        campaginData.invoiceDetails = invoiceDetails
        props.handleCampaignUpdates(props.camp_id, campaginData)
    },[campagin, creative, supportingCreative, targeting, budget, contact])


    useEffect(() => {
        let creativeOb=[...creative]
        setStep1Complete(false)
        if (supportingCreative.length === 0 && ottEnabled) {
            setStep1Complete(false)
            return
        }
        let containsVideo = false
        let containsAudio = false
        // console.log(creative)
        if (creative.length > 0) {
            creative.forEach((asset) => {
                if (asset.type === "audio/mpeg" || asset.type === "audio/wav" || asset.type === "audio/mp3") {
                    containsAudio = true
                }
                if (asset.type === "video/mp4" || asset.type === "video/mov") {
                    containsVideo = true
                }
            })
        }
        //Has Both
        if (containsVideo && containsAudio) {
            if (campagin.company_logo && campagin.companion_banner) {
                if (supportingCreative.length === 4 && ottEnabled) {
                    setStep1Complete(true)
                }
                if (!ottEnabled) {
                    setStep1Complete(true)
                }

            }
        }
        //Has Only Video
        if (containsVideo && !containsAudio) {

            if (campagin.company_logo) {
                if (supportingCreative.length === 4 && ottEnabled) {
                    setStep1Complete(true)
                }
                if (!ottEnabled) {
                    setStep1Complete(true)
                }
            }
        }
        //Has Only Audio
        if (!containsVideo && containsAudio) {
            if (campagin.companion_banner) {
                if (supportingCreative.length === 4 && ottEnabled) {
                    setStep1Complete(true)
                }
                if (!ottEnabled) {
                    setStep1Complete(true)
                }
            }
        }

    }, [creative, supportingCreative, logo, ottEnabled, campagin])

    const toggleUpload = () => {
        setExpandUpload(!expandUpload)
    }
    const toggleTargeting = () => {
        setExpandTargeting(!expandTargeting)
    }
    const togglePayment = () => {
        setExpandPayment(!expandPayment)
    }

    const toggleCampaign = () => {
        setExpandCampaign(!expandCampaign)
    }
    const toggleContact = () => {
        setExpandContact(!expandContact)
    }


    const updateCampaign = (field, value) => {
        let curCampaign = { ...campagin }

        curCampaign[field] = value
        setCampaign(curCampaign)

        validateCampaignSection(curCampaign)
    }

    const validateCampaignSection = (campaignObj) => {

        //console.log(campaignObj)
        const allValuesValid = Object.values(campaignObj).every(value => value !== null && value !== "");

        setStep3Complete(allValuesValid);
        let curCampaign = { ...campaignObj }
        curCampaign.isComplete=allValuesValid
        setCampaign(curCampaign)

    }

    const validateContactSection = (contactObj) => {
        const allValuesValid = Object.values(contactObj).every(value => value !== null && value !== "");
        setStep4Complete(allValuesValid);
        let curContact = { ...contactObj }
        curContact.isComplete=allValuesValid
        setContact(curContact)

    }

    const updateBudget = (field, value) => {
        let curBudget = { ...budget }
        curBudget[field] = value
        setBudget(curBudget)
        validateBudgetSection(curBudget)
    }

    const validateBudgetSection = (budgetObj) => {
        if (budgetObj.budget >= 500) {
            setStep5Complete(true)
        } else {
            setStep5Complete(false)
        }

    }

    const updateTargeting = (field, value) => {
        let curTargeting = { ...targeting }
        curTargeting[field] = value
        setTargeting(curTargeting)
        validateTargetingSection(curTargeting)
    }

    const validateTargetingSection = (curTargeting) => {
        let targetingObj = { ...curTargeting };
    
        // Filter out the 'objective' key and check remaining values
        let allValuesValid = Object.keys(targetingObj)
            .filter(key => key !== 'objective')  // Exclude 'objective'
            .every(key => targetingObj[key] !== null && targetingObj.locals.length>0);
    
        // Update isComplete and step2Complete based on validation result
        if (allValuesValid) {
            targetingObj.isComplete = true;
            setStep2Complete(true);
        } else {
            targetingObj.isComplete = false;
            setStep2Complete(false);
        }
    
        // Set updated targeting object
        setTargeting(targetingObj);
    };
    

    const updateContact = (e, key) => {
        let contactRef = { ...contact }
        contactRef[key] = e.target.value

        validateContactSection(contactRef)
        //Look through values
        setContact(contactRef)
    }

    const handleLogoUpload = (url) => {
        //add utm tag
        if (url !== "") {

            updateCampaign('company_logo', url)
        } else {
            updateCampaign('company_logo', null)
        }
    }

    const handleCompanionBannerUpload = (url) => {
        //add utm tag
        if (url !== "") {
            updateCampaign('companion_banner', url)
        } else {
            updateCampaign('companion_banner', null)
        }

    }

 
    const updateCreative = (type, url, id) => {
        setCreative((prevCreative) => [
            ...prevCreative,
            { type, url, id} // Include campaign_id in the creative data
        ]);
    };
 

    const removeCreative = (url) => {
        // Create a new array excluding the item with the matching URL
        const newSet = creative.filter(item => item.url !== url);

        // Update the state with the new array
        setCreative(newSet);
    };


    const updateSupportingCreative = (url, id) => {
        setSupportingCreative((prevSupportingCreative) => [
            ...prevSupportingCreative,
            { url: url, id: id }
        ]);
        console.log(supportingCreative, url)
    };


    const removeSupportingCreative = (url) => {
        // Create a new array excluding the item with the matching URL
        const newSet = supportingCreative.filter(item => item.url !== url);

        // Update the state with the new array
        setSupportingCreative(newSet);
    };




    const updateLocals = (key, data) => {
        // console.log(key, data);
        updateTargeting("locals", data)
    };

    
    return (
        <div id="master-grid" key={props.camp_id} style={{ marginTop: '0px', margin: 'auto', display: 'block', width:'100%' }}>
            <div id="create-container-new" >

                {/* <div style={{ marginBottom: "40px" }} onClick={() => {if(referralId !==null){window.location.href = "/?referral_id="+referralId}else{window.location.href = "/"}}}>
                <img id="create_logo" src={Logo} alt="Streamwave_Logo" />
            </div> */}
                {/* A/V Toggle */}

                <div>
                    {activeToggle === "video" ?
                        <section className="section-wrapper" style={{ maxHeight: expandUpload ? 'fit-content' : '68px' }}>
                            <div className="step-header" onClick={() => { toggleUpload() }}>
                                <div className="progress-step-num" style={{ backgroundColor: step1Complete ? 'var(--primary-color)' : 'transparent', color: step1Complete ? 'white' : 'var(--primary-color)' }}>1</div>
                                <div>
                                    <h4>Upload Creative (Audio/Video)</h4>
                                </div>
                                <div className="chevron-container">
                                    {step1Complete ?
                                        <div id={"lottie"+props.camp_id}>
                                        <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation>
                                        </div>
                                        : null
                                    }
                                    <img className="chevron-img" src={chevron} alt="chevron" style={{ transform: expandUpload ? 'rotate(0deg)' : 'rotate(90deg)' }}></img>
                                </div>
                            </div>
                            <div className="create-content-wrapper" style={{ display: expandUpload ? 'block' : 'none' }}>
                            <UploadCreative key={props.camp_id} ottEnabled={(i) => { setOttEnabled(i) }} camp_id={props.camp_id} updateCreative={updateCreative} removeCreative={removeCreative} updateSupportingCreative={updateSupportingCreative} creative={creative} handleLogoUpload={handleLogoUpload} handleCompanionBannerUpload={handleCompanionBannerUpload} type="Video"></UploadCreative>
                            </div>
                        </section> : null
                    }
                    {/* Targeting Objectives */}
                    <section className="section-wrapper" style={{ overflow: 'visible' }}>
                        <div className="step-header" onClick={() => { toggleTargeting() }}>
                            <div className="progress-step-num" style={{ backgroundColor: step2Complete ? 'var(--primary-color)' : 'transparent', color: step2Complete ? 'white' : 'var(--primary-color)' }}>2</div>
                            <h4>Targeting Objectives</h4>
                            <div className="chevron-container">
                                {step2Complete ?
                                    <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation> : null
                                }
                                <img className="chevron-img" src={chevron} alt="chevron" style={{ transform: expandTargeting ? 'rotate(0deg)' : 'rotate(90deg)' }}></img>
                            </div>
                        </div>
                        <div style={{ padding: '20px', overflow: 'visible', display: expandTargeting ? 'block' : 'none' }}>
                            <div style={{ position: 'relative', marginBottom: '16px' }}>
                                <label htmlFor="select-industry">Select OEM
                                    <span className="tooltip" title="Choose an OEM to reveal relevant audience segments.">?</span>
                                </label>
                                <Industries updateIndustries={updateTargeting} updateAudiences={updateTargeting} partner={"AET"}></Industries>
                            </div>
                            <div style={{ position: 'relative', marginBottom: '16px' }}>
                                <label htmlFor="company-name">Geo-Targeting
                                    <span className="tooltip" title="Add one or more locations where you want your ad to be displayed. ">?</span>
                                </label>
                                <Location updateLocals={updateLocals}></Location>
                            </div>


                            <div style={{ position: 'relative' }}>
                                <label htmlFor="company-name">Tell us more about your marketing objectives
                                    <span className="tooltip" title="This helps us get a better understanding of your unique needs and goals.">?</span>
                                </label>
                                <textarea onChange={(e) => { if (e.target.value !== "") { updateTargeting('objective', e.target.value) } else { updateTargeting('objective', null) } }} placeholder="Want a more specific targeting objective? Lets us know here."></textarea>
                            </div>
                        </div>
                    </section>
                    {/* Campaign Details */}
                    <section className="section-wrapper">
                        <div className="step-header" onClick={() => { toggleCampaign() }}>
                            <div className="progress-step-num" style={{ backgroundColor: step3Complete ? 'var(--primary-color)' : 'transparent', color: step3Complete ? 'white' : 'var(--primary-color)' }}>3</div>
                            <h4>Campaign Details</h4>
                            <div className="chevron-container" >
                                {step3Complete ?
                                    <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation> : null
                                }
                                <img className="chevron-img" src={chevron} alt="chevron" style={{ transform: expandContact ? 'rotate(0deg)' : 'rotate(90deg)' }}></img>
                            </div>
                        </div>
                        <div style={{ padding: '20px', overflow: 'visible', display: expandCampaign ? 'block' : 'none' }}>
                            {/* <DateRangeModal updateDates={updateCampaign} /> */}
                            <div className="content-grid">
                                <div className="input-wrapper">
                                    <label htmlFor="start-date">Start Date
                                        <span className="tooltip" title="The start date of you ad campaign">?</span>
                                    </label>
                                    <input type="date" style={{ width: '168px' }} onChange={(e) => { updateCampaign('campagin_start_date', e.target.value) }} />
                                </div>
                                <div className="input-wrapper">
                                    <label htmlFor="end-date">End Date
                                        <span className="tooltip" title="The end date of you ad campaign">?</span>
                                    </label>
                                    <input type="date" style={{ width: '168px' }} onChange={(e) => { updateCampaign('campagin_end_date', e.target.value) }} />
                                </div>
                                <div className="input-wrapper">
                                    <label htmlFor="campaign-name">Campaign Name
                                        <span className="tooltip" title="Your campaign name will be used for performance reports only">?</span>
                                    </label>
                                    <input type="text" onChange={(e) => { updateCampaign('campaign_name', e.target.value) }} />
                                </div>

                                <div className="input-wrapper">
                                    <label htmlFor="landing-page-url">Landing Page URL
                                        <span className="tooltip" title="Enter the URL where users will be directed after clicking on your ad.">?</span>
                                    </label>
                                    <input type="text" onChange={(e) => { updateCampaign('campaign_landing_page', e.target.value) }} />
                                </div>
                                <div className="input-wrapper">
                                    <label htmlFor="company-name">Dealership Name</label>
                                    <input type="text" value={campagin?.company_name} onChange={(e) => { updateCampaign('company_name', e.target.value) }} />
                                </div>
                                <div className="input-wrapper">
                                    <label htmlFor="company-webiste">Dealership Website</label>
                                    <input type="url" value={campagin?.company_website} onChange={(e) => { updateCampaign('company_website', e.target.value) }} />
                                </div>
                                <div className="input-wrapper">
                                    <label htmlFor="company-webiste">Dealership Address</label>
                                    <input type="url" value={campagin?.business_address} onChange={(e) => { updateCampaign('business_address', e.target.value) }} />
                                </div>

                            </div>

                        </div>

                    </section>

                    {/* <section className="section-wrapper">
                <div className="step-header" onClick={() => { toggleContact() }}>
                    <div className="progress-step-num" style={{ backgroundColor: step4Complete ? 'var(--primary-color)' : 'transparent', color: step4Complete ? 'white' : 'var(--primary-color)' }}>4</div>
                    <h4>Contact Information</h4>
                    <div className="chevron-container" >
                        {step4Complete ?
                            <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation> : null
                        }
                        <img className="chevron-img" src={chevron} alt="chevron" style={{ transform: expandContact ? 'rotate(0deg)' : 'rotate(90deg)' }}></img>
                    </div>
                </div>
                {expandContact ?
                    <div className='create-content-wrapper'>
                        <div className="content-grid">
                            <div className="input-wrapper">
                                <label htmlFor="company-name">First Name</label>
                                <input type="text" required onChange={(e) => { updateContact(e, 'first_name') }} value={contact.first_name} />
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="company-name">Last Name</label>
                                <input type="text" required onChange={(e) => { updateContact(e, 'last_name') }} value={contact.last_name} />
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="company-name">Phone Number</label>
                                <input type="tel" required onChange={(e) => { updateContact(e, 'phone') }} value={contact.phone} />
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="company-name">Email</label>
                                <input type="email" required onChange={(e) => { updateContact(e, 'email') }} value={contact.email} />
                            </div>
                        </div>
                        <p>Designate a main point of contact for this ad campaign. They'll receive monthly performance reports and be our go-to contact for any issues. Let's work together to ensure the best results for your business.</p>
                    </div> : null
                }
            </section> */}
                    <section className="section-wrapper">
                        <div className="step-header" onClick={() => { togglePayment() }}>
                            <div className="progress-step-num" style={{ backgroundColor: step5Complete ? 'var(--primary-color)' : 'transparent', color: step5Complete ? 'white' : 'var(--primary-color)' }}>4</div>
                            <h4>Budget</h4>
                            <div className="chevron-container" onClick={() => { togglePayment() }}>
                                {step5Complete ?
                                    <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation> : null
                                }

                                <img className="chevron-img" src={chevron} alt="chevron" style={{ transform: expandPayment ? 'rotate(0deg)' : 'rotate(90deg)' }}></img>
                            </div>
                        </div>
                        {expandPayment ?
                            <div className='create-content-wrapper'>
                                {/* <div className="gtg">Looking good! Finalize your ad buy and you're all set!</div> */}
                                <div style={{ display: 'grid', placeContent: 'center' }}>
                                    {campagin.campagin_duration * 80 > 3500 ?
                                        <p style={{ textAlign: 'center', fontSize: '14px', width: 'auto', paddingLeft: '5%', paddingRight: '5%' }}>
                                            {"Based on your flight dates ("}<strong>{campagin.campagin_duration} days</strong>{") we recommend a budget of at least "}
                                            <strong>{"$" + campagin.campagin_duration * 80 + " "}</strong>($80/day) {"to optimize your return on ad spend (ROAS)."}
                                        </p> : null
                                    }
                                    <div className="input-wrapper">
                                        <div style={{ textAlign: 'center', fontSize: '14px', fontWeight: '600' }} >Set Budget (USD)</div>
                                        <input type="number" min="500" style={{ maxWidth: '300px', color: 'var(--primary-color)', fontSize: '24px', fontWeight: 600, textAlign: 'center', outline: budget.budget < 500 ? '2px solid red' : '2px solid var(--primary-color)' }} required onChange={(e) => { updateBudget("budget", e.target.value) }} value={budget.budget} />
                                    </div>
                                    {budget.budget < 500 ?
                                        <p style={{ fontSize: '12px', color: 'red', fontWeight: 500, textAlign: 'center', marginTop: '4px' }}>*Budget too low: Please enter a campaign budget over $500*</p> : <p style={{ fontSize: '12px', fontWeight: 500, textAlign: 'center', marginTop: '4px' }}>*Minimum required budget is $500 per campaign, you can create multiple campaigns per order*</p>
                                    }
                                    {/* <div style={{ position: 'relative' }}>
                                <label htmlFor="company-name">Invoice Details (Optional)
                                    <span className="tooltip" title="Add details for your campaign, for example if the budget is split across multiple model lines">?</span>
                                </label>
                                <textarea style={{height:'50px'}} onChange={(e) => {setInvoiceDetails(e.target.value)}} placeholder="Provide any additional details you want represented on your campaign invoice"></textarea>
                            </div> */}
                                    {/* <div style={{ display: 'block', margin: 'auto' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', }}>
                                            <input type="checkbox" checked={budget.tc_checked} onChange={() => { updateBudget('tc_checked', !budget.tc_checked) }} />
                                            <p style={{ marginLeft: '8px', fontWeight: 500, fontSize: '14px', opacity: .9 }} >I agree to the <a href="https://sites.google.com/streamwave.io/termsofservice/home">terms of service</a></p>
                                        </div>
                                    </div> */}
                                    {/* {step5Complete ?
                                        <button id="proceed-to-payment" style={{ marginBottom: '16px' }} onClick={sendToDB}>{proceedToPayment ? "One Moment..." : "Proceed to Payment"}</button> : <button id="proceed-to-payment" style={{ marginBottom: '16px', opacity: .5 }} disabled>Proceed to Payment</button>
                                    } */}

                                </div>

                            </div> : null
                        }
                    </section >
                    {/* <button onClick={()=>{sendToDB()}}>Create Oppertunity</button> */}
                    {/* <button onClick={()=>{createAdCampagin()}}>Send to BD</button> */}
                    {/* <button onClick={()=>{createStripePaymentSession()}}>Test Me</button> */}
                    {/* <PaymentForm></PaymentForm> */}

                </div>

                {/* <img src={bg} alt="bg" style={{position:'fixed', width:'100%', zIndex:'10'}}/> */}
            </div >


        </div>
    );
}

export default Campaign;